import { Box, Button, Typography } from '@mui/material';
import { Close, Warning } from '@mui/icons-material';
import { FC } from 'react';
import { border, borderColor } from '@mui/system';
const useStyles = {
  alertContainer: {
    minHeight: '2.6rem',
    background: 'orange',
    display: 'flex',
    alignItems: 'center',
    padding: '0 2rem',
    textAlign: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },
  alertContent: { padding: 0, margin: 0, fontWeight: 500 },

  exitButton: {
    color: '#fff',
    padding: '0px 6px',
    borderColor: '#fff',
    '&:hover': {
      borderColor: '#fff'
    },
    '&.Mui-disabled': {
      color: '#fff',
      borderColor: '#fff'
    }
  }
};

type WarningRibbonType = {
  show: boolean;
  handleHide?: () => void;
  handleExit?: () => void;
  exitButtontitle?: string | JSX.Element;
  backgroundColour?: string;
  children?: JSX.Element;
  disableExitButton?: boolean;
};

const WarningRibbon: FC<WarningRibbonType> = (props) => {
  const { show, handleHide, backgroundColour, handleExit, exitButtontitle = 'Exit', disableExitButton } = props;

  const classes = useStyles;
  const handleOnClick = () => {
    if (handleHide) handleHide();
  };

  return show ? (
    <Box
      sx={classes.alertContainer}
      style={{
        color: '#fff',
        background: backgroundColour === 'red' ? '#D32F2F' : 'orange'
      }}
    >
      <Warning fontSize="small" />
      <Typography sx={classes.alertContent}>{props?.children}</Typography>

      {handleHide && <Close fontSize="small" onClick={handleOnClick} />}
      {handleExit && (
        <Button
          sx={classes.exitButton}
          size="small"
          variant="outlined"
          onClick={handleExit}
          disabled={disableExitButton}
        >
          {exitButtontitle}
        </Button>
      )}
    </Box>
  ) : null;
};

export default WarningRibbon;
