import { setInterceptors, setHeader } from '../api/interceptor';
import { createContext, useEffect, useMemo, useState } from 'react';
import AuthManager from '../app/auth/manager/AuthManager';
import { useQuery } from '../common/hooks/useQuery';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export type UserAuthInfo = {
  token: string;
  user?: any;
  id?: string;
  syndicateType?: string;
  permalink?: string;
  refreshToken?: string;
};

export type AuthContextProps = {
  authState: UserAuthInfo;
  isUserAuthenticated: Function;
  isAuthLoading: boolean;
  setUserSession: Function;
  getUserSession: Function;
};

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export const AuthContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [authState, setAuthState] = useState<UserAuthInfo>({} as UserAuthInfo);

  const [isAuthLoading, setIsAuthLoading] = useState(true);

  const authManager = new AuthManager();
  const queryParams = useQuery();
  const navigate = useNavigate();

  const setUserSession = (
    token: string,
    user: any,
    id: string,
    syndicateType: string,
    permalink: string,
    refreshToken?: string
  ) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('syndicate_id', id);
    localStorage.setItem('syndicate_type', syndicateType);
    localStorage.setItem('syndicate_permalink', permalink);
    if (refreshToken) localStorage.setItem('refreshToken', refreshToken);
    setAuthState({ token, user, id, syndicateType, permalink, refreshToken });
  };

  const getUserSession = (): UserAuthInfo => {
    const token = localStorage.getItem('token') as string;
    const user: any = localStorage.getItem('user');
    const id = localStorage.getItem('syndicate_id') as string;
    const syndicateType = localStorage.getItem('syndicate_type') as string;
    const permalink = localStorage.getItem('syndicate_permalink') as string;
    const refreshToken = localStorage.getItem('refreshToken') as string;
    return { token, user: JSON.parse(user), id, syndicateType, permalink, refreshToken };
  };

  const appRedirect = async () => {
    try {
      const accessTokenSwitchUser: any = !!queryParams.get('access_token')
        ? queryParams.get('access_token')?.replaceAll(' ', '+')
        : queryParams.get('access_token');
      const accessSwitchUser = queryParams.get('access');
      if (accessSwitchUser) {
        localStorage.setItem('token', accessTokenSwitchUser);
        setInterceptors();
        await new Promise((resolve) => setTimeout(resolve, 2000)); // Ensure token is set before proceeding
        const userDetails = await authManager.getSyndicateUserDetails();
        setUserSession(
          accessTokenSwitchUser,
          userDetails.user,
          userDetails.syndicate_id,
          userDetails.syndicate_type,
          userDetails.syndicate_permalink
        );
        navigate('/');
      } else {
        const token = getUserSession();
        if (Object.keys(token)?.length) {
          setAuthState(token);
          setInterceptors();
        }
      }
    } catch (error) {
      console.error('Error in redirecting', error);
      toast.error('Error in redirecting');
    } finally {
      setIsAuthLoading(false);
    }
  };
  useEffect(() => {
    setHeader();
    appRedirect();
  }, []);

  const isUserAuthenticated = () => {
    const token = localStorage.getItem('token');
    return !!token;
  };

  const stateValues = useMemo(
    () => ({
      authState,
      isUserAuthenticated,
      isAuthLoading,
      setUserSession,
      getUserSession
    }),
    [isAuthLoading, authState]
  );

  return <AuthContext.Provider value={stateValues}>{children}</AuthContext.Provider>;
};
