import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/authContext';
import MainLoader from '../app/common/loaders/MainLoader';

const PrivateRoute = (props: any) => {
  const { isAuthLoading } = useContext(AuthContext);
  const { children } = props;
  const isAuthenticated = localStorage.getItem('token');
  if (isAuthLoading) {
    return <MainLoader />;
  }
  if (!isAuthenticated) {
    return <Navigate to="login" replace />;
  }

  return children;
};

export default PrivateRoute;
