import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/authContext';
import WarningRibbon from '../../../ui-library/components/warning-ribbon/WarningRibbon';
import { toast } from 'react-toastify';
import AuthManager from '../../auth/manager/AuthManager';

const ImpersonateRibbon = () => {
  const authManagementManager = new AuthManager();
  const [exiting, setExiting] = useState(false);
  const { authState } = useContext(AuthContext);
  const { user } = authState;

  const handleExitImpersonate = async () => {
    try {
      setExiting(true);
      await authManagementManager.exitImpersonation(user?.impersonator);
      localStorage.clear();
      sessionStorage.clear();
      setTimeout(() => {
        window.close();
      }, 1000);
      setExiting(false);
    } catch (e: any) {
      toast.error(e?.message || 'something went wrong');
      setExiting(false);
    }
  };

  return (
    <WarningRibbon
      show={user?.impersonator}
      backgroundColour="red"
      handleExit={handleExitImpersonate}
      exitButtontitle={
        exiting ? (
          <>
            Exiting Impersonation <i className="fa-solid fa-spinner fa-spin-pulse" style={{ marginLeft: 5 }}></i>
          </>
        ) : (
          'Exit Impersonation'
        )
      }
      disableExitButton={exiting}
    >
      <>
        You are currently impersonating <b>'{user?.name}'</b>
      </>
    </WarningRibbon>
  );
};

export default ImpersonateRibbon;
