import { toast } from 'react-toastify';
import { AuthContextProps } from '../../../contexts/authContext';
import AuthService from '../service/AuthService';

export default class AuthManager {
  private userId: string;
  private permalink: string;

  constructor(authContext?: AuthContextProps) {
    this.userId = authContext?.authState?.id as string;
    this.permalink = authContext?.authState?.permalink as string;
  }

  async createDeviceData() {
    try {
      const response: any = await AuthService.createDeviceData();
      return response?.data;
    } catch (error: any) {
      toast.error(error?.message);
      throw error;
    }
  }

  async getSyndicateUserDetails() {
    try {
      const response: any = await AuthService.getSyndicateUserDetails();
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  }

  async exitImpersonation(impersonatorId: string) {
    try {
      const data = { impersonator_id: impersonatorId };
      const response: any = await AuthService.exitImpersonation(data);
      return response?.data;
    } catch (error: any) {
      throw error;
    }
  }
}
