import { useState } from 'react';
import SideBar from '../../sidebar';
import { Outlet } from 'react-router-dom';
import useStyles from './styles';
import { Box } from '@mui/material';
import ImpersonateRibbon from '../../common/components/ImpersonateRibbon';

const Home = () => {
  const classes = useStyles();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  const toggleSidebar = (flag: boolean) => {
    setIsSidebarOpen(flag);
  };
  const onToggle = (flag: boolean) => {
    toggleSidebar(flag);
  };

  return (
    <Box
      sx={{
        overflowY: 'scroll',
        maxHeight: '100vh'
      }}
    >
      <SideBar onToggle={onToggle} />
      <Box
        className={` ${isSidebarOpen ? classes.homeOnSideBarOpen : classes.homeOnSideBarClose}`}
        sx={{ position: 'sticky', top: 0 }}
      >
        <ImpersonateRibbon />
      </Box>

      <Box
        component="main"
        sx={{ flex: 1 }}
        className={`${classes.mainBody} ${isSidebarOpen ? classes.homeOnSideBarOpen : classes.homeOnSideBarClose}`}
      >
        <Box maxWidth="1160px">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;
