import { makeStyles } from '@mui/styles';
// import { getColor } from "@trica-equity/ui-library.styles.colors";

export const sideMainLayout = {
  marginWhenSideBarOpen: '278px',
  marginWhenSideBarClose: '64px'
};

export default makeStyles({
  homeOnSideBarOpen: {
    marginLeft: sideMainLayout.marginWhenSideBarOpen,
    transition: 'margin 1s ease-in-out'
  },
  homeOnSideBarClose: {
    marginLeft: sideMainLayout.marginWhenSideBarClose
  },
  mainBody: {
    transition: 'margin 1s ease-in-out',
    position: 'relative',
    background: '#fff',
    padding: 25
    // overflowY: 'scroll',
  }
});
